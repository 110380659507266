import linkedinIcon from '../assets/linkedin_icon.png';
import emailIcon from '../assets/email_icon.png';
import githubIcon from '../assets/github_icon.png';

const SOCIAL_PROFILES = [
    {
        id: 1,
        icon: linkedinIcon,
        link: 'https://www.linkedin.com/in/ifat-ori-13725b118/'
    },
    {
        id: 3,
        icon: emailIcon,
        link: 'mailto: ifat.email@gmail.com'
    },
    {
        id: 4,
        icon: githubIcon,
        link: 'https://github.com/ifat-github'
    }
];

export default SOCIAL_PROFILES;