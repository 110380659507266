import React from 'react';

const Artist = ({ artist }) => {
    if (!artist) {
        return (
            <div>
                <h3>Couldn't find this artist, please try again.</h3>
            </div>
        );
    } 
    
    const { images, name, followers, genres } = artist;
    return (
        <div>
            <h3>{name}</h3>
            <p>{followers.total} followers</p>
            <p>{genres.join(', ')}</p>
            <img
                src={images[0] && images[0].url} 
                alt='artist-profile'
                style={{ 
                    height: 200,
                    width: 200,
                    borderRadius: 100,
                    objectFit: 'cover'
                }}/>
        </div>
    );
}

export default Artist;